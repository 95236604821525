




































































import { Component, Vue } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
import Simulador from '@/plugins/api/Simulador';
import RdStation from '@/plugins/api/RdStation';
import CheckIcon from 'vue-material-design-icons/Check.vue';
import CancelIcon from 'vue-material-design-icons/Cancel.vue';

@Component({
  components: { CheckIcon, CancelIcon },
  computed: {
    ...mapState(['cotacao']),
    ...mapGetters(['veiculo_ano', 'valor_mensalidade', 'get_email']),
  },
})
export default class Resumo extends Vue {
  public timeout = 0;

  public mounted() {
    this.$store.commit('SET_SIDEBAR_ENABLED', true);
    this.$store.commit('SET_SIDEBAR_SHOWING', this.$vuetify.breakpoint.smAndUp);

    window.funnelytics.functions.step();
  }

  public beforeDestroy() {
    clearTimeout(this.timeout);
  }

  get cotacao() {
    return this.$store.state.cotacao;
  }

  get protocolo() {
    return this.cotacao.protocolo;
  }

  get preco() {
    return this.$store.getters.valor_fipe;
  }

  public sendInteresse(interesse: boolean) {
    const baseUrl = '//proteautobrasil.com.br/cotacao/proposta-online';
    let pagina = 'interessado';
    let identificador = '';
    let resposta = '';

    if (!interesse) {
      pagina = 'nao-interessado';
    }

    if (this.cotacao.valor_fipe > 100000) {
      pagina += '-mais-100';
    }

    if (interesse) {
      window.fbq('track', 'Lead');
      resposta = 'Sim';
      identificador = 'Sim, Agendar Vistoria!';
    } else {
      resposta = 'Não';
      identificador = 'Não tenho interesse';
    }

    Simulador.update({
      id: this.$store.state.cotacao.id,
      deseja_contato: interesse,
    }).then(() => {
      RdStation.conversion({
        'identificador': identificador,
        'Interesse em contratação': resposta,
        'email': this.$store.getters.get_email,
      }).then();
    });

    let newUrl = `${baseUrl}/${pagina}.php?id=${this.protocolo}`;

    if (this.cotacao.cod_indicador !== 0) {
      newUrl += `&ref=${this.cotacao.cod_indicador}`;
    }

    window.location.href = newUrl;
  }
}
